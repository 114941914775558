import { useStaticQuery, graphql } from 'gatsby'

const useContactUsMetadata = () => {
  const query = useStaticQuery(graphql`
    query ContactUsMetadata {
      bannerBG: file(relativePath: { eq: "contact-us/banner/bg.png" }) {
        id
        ...ContactUsImageOptimize
      }
      ctaCardsRightImg: file(
        relativePath: { eq: "contact-us/contact/support-img.jpg" }
      ) {
        id
        ...ContactUsImageOptimize
      }
      ctaCardsLeftImg: file(
        relativePath: { eq: "free-consultation/contact/right-img.jpg" }
      ) {
        id
        ...ContactUsImageOptimize
      }

      socialBG: file(relativePath: { eq: "contact-us/social/bg.jpg" }) {
        id
        ...ContactUsImageOptimize
      }
      socialCard1: file(relativePath: { eq: "contact-us/social/card-1.jpg" }) {
        id
        ...ContactUsImageOptimize
      }
      socialCard2: file(relativePath: { eq: "contact-us/social/card-2.jpg" }) {
        id
        ...ContactUsImageOptimize
      }
      socialCard3: file(relativePath: { eq: "contact-us/social/card-3.jpg" }) {
        id
        ...ContactUsImageOptimize
      }
      socialCard4: file(relativePath: { eq: "contact-us/social/card-4.jpg" }) {
        id
        ...ContactUsImageOptimize
      }
    }

    fragment ContactUsImageOptimize on File {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          formats: [AUTO, WEBP]
        )
      }
    }
  `)

  return query
}

export default useContactUsMetadata
