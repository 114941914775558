import React from 'react'
import ElementImage from './Image'

const ElementImageFile = ({
  src,
  width,
  height,
  srcset,
  sizes,
  alt = '',
  lazy = true,
  className,
}) => {
  return (
    <ElementImage
      src={src?.images?.fallback.src}
      width={width ? width : src?.width}
      height={height ? height : src?.height}
      srcSet={srcset ? srcset : src?.images?.fallback.srcSet}
      sizes={sizes ? sizes : src?.images?.fallback.sizes}
      alt={alt}
      splideLazyload={false}
      lazy={lazy}
      className={className}
    />
  )
}

export default ElementImageFile
