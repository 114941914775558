import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { navigate } from 'gatsby'
import clsx from 'clsx'

import Breadcrumbs from '@components/seo/Breadcrumbs'
import ConsultationForm from '@components/global/forms/ConsultationForm'
import { Button } from '@components/global/buttons/Buttons'
import SvgPhoneCall from '@svg/phoneCall.inline'
import ElementImage from '@components/global/element/Image'
import * as cx from './ConsultationBanner.module.scss'

const ConsultationBanner = ({
  wrapperClassName,
  hasDarkText,
  hasSupportFormData,
  breadcrumbs,
  bg,
  heading,
  subheading1,
  subheading2,
  subheading3,
  stressSubheading,
  formHeading,
  formButtonText,
  headingClassName = 'heading-1',
  subheading1ClassName = 'subtitle-7',
  subheading2ClassName = 'subtitle-3',
  subheading3ClassName = 'subtitle-5',
  stressSubheadingClassName = 'subtitle-4',
  formHeadingClassName,
  callButtonText = 'Give us a call',
  is404,
  subheading404,
  Button404Text = 'Back to Homepage',
}) => {
  return (
    <section
      className={clsx(wrapperClassName, [cx.wrapper], {
        [cx.darkText]: hasDarkText,
        [cx.is404]: is404,
      })}
    >
      <ElementImage
        className={cx.bg}
        src={bg}
        alt="consultation background"
        lazy={false}
      />
      <div className={cx.container}>
        <Breadcrumbs
          wrapperClassName={`${cx.breadcrumbs} global-breadcrumbs`}
          data={breadcrumbs}
        />

        <h2 className={cx.heading}>
          <span className={subheading1ClassName}>{subheading1}</span>
          <span className={headingClassName}>{heading}</span>

          {!is404 && (
            <Button large text={callButtonText} svg={<SvgPhoneCall />} />
          )}

          <hr />
          {subheading2 && !is404 && (
            <span className={subheading2ClassName}>{parse(subheading2)}</span>
          )}
          {stressSubheading && (
            <span className={stressSubheadingClassName}>
              {stressSubheading}
            </span>
          )}
          {subheading3 && (
            <span className={subheading3ClassName}>{subheading3}</span>
          )}

          {is404 && subheading404 && (
            <>
              <span className={subheading2ClassName}>
                <span>{subheading404.text1}</span>
                <span className={cx.accentText}>
                  {subheading404.accentText}
                </span>
                <span>{subheading404.text2}</span>
              </span>

              <Button text={Button404Text} onClick={() => navigate('/')} />
            </>
          )}
        </h2>

        <ConsultationForm
          hasSupportData={hasSupportFormData}
          has404Data={is404}
          heading={formHeading}
          headingClassName={formHeadingClassName}
          buttonText={formButtonText}
        />
      </div>
    </section>
  )
}

ConsultationBanner.propTypes = {
  wrapperClassName: PropTypes.string,
  hasDarkText: PropTypes.bool,
  hasSupportFormData: PropTypes.bool,
  is404: PropTypes.bool,
  breadcrumbs: PropTypes.array,
  bg: PropTypes.string,
  heading: PropTypes.string,
  subheading1: PropTypes.string,
  subheading2: PropTypes.string,
  subheading3: PropTypes.string,
  subheading404: PropTypes.object,
  stressSubheading: PropTypes.string,
  formHeading: PropTypes.string,
  formHeadingClassName: PropTypes.string,
  formButtonText: PropTypes.string,
  headingClassName: PropTypes.string,
  subheading1ClassName: PropTypes.string,
  subheading2ClassName: PropTypes.string,
  subheading3ClassName: PropTypes.string,
  stressSubheadingClassName: PropTypes.string,
  callButtonText: PropTypes.string,
}

export default ConsultationBanner
