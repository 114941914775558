import React from 'react'

const SvgPhoneCall = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
    >
      <path
        d="M11.7733 5.90365C12.4733 6.0539 13.1167 6.43051 13.6211 6.9853C14.1254 7.54009 14.4678 8.24781 14.6044 9.01788M11.7733 2.75C13.2277 2.92774 14.584 3.6442 15.6194 4.78175C16.6549 5.91929 17.3079 7.41032 17.4713 9.01M16.7546 15.3015V17.6668C16.7554 17.8863 16.7145 18.1037 16.6345 18.3049C16.5546 18.5061 16.4373 18.6867 16.2902 18.8351C16.1431 18.9835 15.9695 19.0965 15.7804 19.1669C15.5913 19.2372 15.3909 19.2634 15.1921 19.2436C12.9866 18.98 10.868 18.151 9.00664 16.8232C7.27488 15.6127 5.80664 13.9976 4.70621 12.0927C3.4949 10.0359 2.74108 7.69413 2.50582 5.25715C2.48791 5.03913 2.51146 4.8194 2.57498 4.61194C2.6385 4.40448 2.7406 4.21384 2.87477 4.05216C3.00894 3.89048 3.17225 3.7613 3.35429 3.67285C3.53633 3.5844 3.73312 3.53862 3.93213 3.53841H6.08235C6.43018 3.53465 6.7674 3.67014 7.03114 3.91964C7.29488 4.16913 7.46714 4.51561 7.51582 4.89448C7.60658 5.65141 7.77489 6.39462 8.01754 7.10992C8.11397 7.39212 8.13484 7.6988 8.07768 7.99364C8.02052 8.28848 7.88771 8.55911 7.69501 8.77348L6.78475 9.77476C7.80507 11.7486 9.2908 13.3829 11.0852 14.5052L11.9954 13.504C12.1903 13.292 12.4363 13.1459 12.7044 13.083C12.9724 13.0201 13.2512 13.0431 13.5078 13.1492C14.158 13.4161 14.8337 13.6012 15.5218 13.7011C15.87 13.7551 16.1879 13.948 16.4152 14.2431C16.6425 14.5382 16.7633 14.9149 16.7546 15.3015Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgPhoneCall
