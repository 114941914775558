import { useStaticQuery, graphql } from 'gatsby'

const useThe404Metadata = () => {
  const query = useStaticQuery(graphql`
    query the404Metadata {
      bannerBG: file(relativePath: { eq: "the-404/banner-bg.png" }) {
        id
        ...The404ImageOptimize
      }
      bannerBGPhone: file(
        relativePath: { eq: "the-404/banner-bg-phone-2.png" }
      ) {
        id
        ...The404ImageOptimize
      }
      blogBGPhone: file(relativePath: { eq: "the-404/blog-bg-phone.jpg" }) {
        id
        ...The404ImageOptimize
      }
    }

    fragment The404ImageOptimize on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  return query
}

export default useThe404Metadata
