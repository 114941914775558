import { useStaticQuery, graphql } from 'gatsby'

export const useBlogMetadata = () => {
  const query = useStaticQuery(graphql`
    query BlogMetaData($limit: Int = 10) {
      allWpPost(limit: $limit) {
        edges {
          node {
            id
            title
            slug
            excerpt
            primaryCategory {
              node {
                name
                slug
              }
            }
            featuredImage {
              node {
                id
                url: sourceUrl
                srcSet
                sizes
                width
                height
                alt: altText
              }
            }
          }
        }
      }
    }
  `)

  return query
}
