import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import SvgFacebook from '@svg/iconFacebook.inline'
import SvgInstagram from '@svg/iconInstagram.inline'
import SvgLinkedIn from '@svg/iconLinkedIn.inline'
import SvgYouTube from '@svg/iconYouTube.inline'
import SvgPinterest from '@svg/iconPinterest.inline'
import { useContactDetailsMetadata } from '@hooks/contact-details-metadata'
import useContactUsMetadata from '@hooks/contact-us-metadata'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './SocialConnectCards.module.scss'

const SocialConnectCards = ({
  wrapperClassName,
  bg,
  heading,
  subheading = 'Follow Us On Social For More Marketing Tips',
  items,
  altItems,
  headingClassName = 'subtitle-7',
}) => {
  const data = useContactDetailsMetadata().wp.globalFields
  const {
    socialBG: defaultSocialBG,
    socialCard1,
    socialCard2,
    socialCard3,
    socialCard4,
  } = useContactUsMetadata()

  const defaultSocialCardsImg = [
    socialCard1,
    socialCard2,
    socialCard3,
    socialCard4,
  ]

  const defaultImgAlt = [
    'Tips to get Real Estate Leads',
    'Build a Remarkable Real Estate Brand',
    '10 Best Real Estate Websites',
    'Using Chatbots to boost your Google ranking',
  ]

  const socialIcons = [
    {
      href: data.socials.facebook,
      icon: <SvgFacebook />,
      cx: cx.facebook,
      sr: 'Facebook',
    },
    {
      href: data.socials.instagram,
      icon: <SvgInstagram />,
      cx: cx.instagram,
      sr: 'Instagram',
    },
    {
      href: data.socials.linkedin,
      icon: <SvgLinkedIn />,
      cx: cx.linkedin,
      sr: 'LinkedIn',
    },
    {
      href: data.socials.youtube,
      icon: <SvgYouTube />,
      cx: cx.youtube,
      sr: 'Youtube',
    },
    {
      href: data.socials.pinterest,
      icon: <SvgPinterest />,
      cx: cx.pinterest,
      sr: 'Pinterest',
    },
  ]
  const sectionBG = bg || defaultSocialBG
  const socialCardsImg = items || defaultSocialCardsImg
  const imgAlt = altItems || defaultImgAlt

  return (
    <section
      className={clsx([cx.wrapper], wrapperClassName)}
      style={{
        backgroundImage: `url(${extractImage(sectionBG)})`,
      }}
    >
      {heading && <h4 className={headingClassName}>{heading}</h4>}
      <div className={cx.container}>
        {socialCardsImg?.map((item, key) => {
          return (
            <ElementImage
              key={key}
              src={extractImage(item)}
              alt={imgAlt[key]}
            />
          )
        })}
      </div>
      <h5 className={headingClassName}>{subheading}</h5>
      <div className={cx.links}>
        {socialIcons?.map((item, key) => {
          return (
            <a
              key={key}
              href={item?.href}
              target="_blank"
              className={item?.cx}
              rel="noreferrer"
            >
              {item?.icon} <span className="sr-only">{item?.sr} </span>
            </a>
          )
        })}
      </div>
    </section>
  )
}

SocialConnectCards.propTypes = {
  wrapperClassName: PropTypes.string,
  bg: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  items: PropTypes.array,
  altItems: PropTypes.array,
  headingClassName: PropTypes.string,
}

export default SocialConnectCards
