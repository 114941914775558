import React from 'react'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import ConsultationBanner from '@components/global/banner/ConsultationBanner'
import BlogPostsV3 from '@components/global/blog/BlogPostsV3'
import SocialConnectCards from '@components/global/cards/SocialConnectCards'

import {
  pageData as page,
  bannerData,
  socialConnectData,
} from '@src/data/The404'
import useThe404Metadata from '@hooks/the-404-metadata'
import useWindowSize from '@hooks/useWindowSize'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './404.module.scss'

const NotFoundPage = () => {
  const { bannerBG, bannerBGPhone, blogBGPhone } = useThe404Metadata()
  const isPhone = useWindowSize().width <= 767

  return (
    <LayoutInnerPage hasBlackTransparentNav wrapperClassName={cx.wrapper}>
      <Seo title={page.title} uri={page.uri} />
      <ConsultationBanner
        hasDarkText
        is404
        wrapperClassName={cx.banner}
        breadcrumbs={page.breadcrumbs}
        bg={extractImage(isPhone ? bannerBGPhone : bannerBG)}
        heading={bannerData.heading.text}
        subheading1={bannerData.subheading1.text}
        subheading404={bannerData.subheading404}
        formHeading={bannerData.form.heading}
        formHeadingClassName={bannerData.form.headingClassName}
      />
      <BlogPostsV3
        wrapperClassName={cx.blog}
        title={page.blogTitle}
        background={extractImage(isPhone && blogBGPhone)}
      />
      <SocialConnectCards
        wrapperClassName={cx.social}
        subheading={socialConnectData.subheading}
      />
    </LayoutInnerPage>
  )
}

export default NotFoundPage
