export const modifyImageKeys = (obj) => {
  return {
    images: {
      fallback: {
        src: obj.url,
        srcSet: obj.srcset,
        sizes: obj.sizes,
      },
    },
    width: obj.width,
    height: obj.height,
  }
}
