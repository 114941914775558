import React from 'react'

export const pageData = {
  title: '404: Not Found',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: '404 Page',
      url: '/404',
    },
  ],
  uri: '/404',
  blogTitle: 'READ UP TO LEVEL UP YOUR BUSINESS',
}

export const bannerData = {
  heading: {
    text: "Looks like the page you're looking for is missing.",
    className: 'heading-1',
  },
  subheading1: {
    text: 'Page Not Found',
    className: 'subtitle-7',
  },
  subheading404: {
    text1:
      'Check the URL to make sure the address was typed correctly, or go back to',
    accentText: ' our homepage',
    text2: ' to continue browsing our website.',
    className: 'subtitle-3',
  },
  form: {
    heading: 'NEED ASSISTANCE? CONTACT US BELOW.',
    headingClassName: 'heading-6',
    buttonText: 'Submit',
  },
}

export const socialConnectData = {
  subheading: 'Follow us on social',
  className: 'subtitle-7',
}
